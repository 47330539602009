import React from 'react';

import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';

import {
  getEligiblePlanNameByStartDate,
  getNearestStartDateFromFutureEligiblePPs,
  isWithPaymentMethodsExceptEligiblePPs,
} from '../Pricing/helpers/getChallengeEligiblePaidPlans';
import { format } from 'date-fns';

import { Modal } from '../../../../../components-shared/Modal';

import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
  TextButton,
} from 'wix-ui-tpa/cssVars';

import { st, classes } from './CantJoinModal.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useMemberPaidPlans } from '../../../../../contexts/PaidPlans/paidPlansContext';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';

export interface ICantJoinModal {
  isOpen: boolean;
  onSecondaryLinkClick?(): void;
  onClose?(): void;
}

export const CantJoinModal: FCWithChildren<ICantJoinModal> = ({
  isOpen,
  onSecondaryLinkClick,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { eligiblePlans = [], userPaidPlans = [] } = useMemberPaidPlans();
  const { challengeData: { challenge } = { challenge: null } } =
    useChallengeData();
  const bi = useBi();

  const elPPStartDate = getNearestStartDateFromFutureEligiblePPs(
    eligiblePlans,
    userPaidPlans,
    challenge?.id,
  );

  const elPPName =
    elPPStartDate &&
    getEligiblePlanNameByStartDate(
      eligiblePlans,
      userPaidPlans,
      challenge?.id,
      elPPStartDate,
    );

  const isWithOtherPaymentMethods = isWithPaymentMethodsExceptEligiblePPs(
    eligiblePlans,
    userPaidPlans,
    challenge,
  );

  return (
    <div data-hook="cant-join">
      <Modal opened={isOpen} onClose={onClose}>
        <div
          className={st(classes.root, {
            mobile: isMobile,
          })}
        >
          <h1 className={classes.title}>
            {t('live.modal.cant-join.title', {
              planName: elPPName,
            })}
          </h1>

          <p className={classes.description}>
            {t('live.modal.cant-join.description', {
              startDate: elPPStartDate ? format(elPPStartDate, 'PP') : '',
            })}
          </p>

          <div className={classes.controls}>
            <Button
              disabled={false}
              className={classes.cta}
              priority={ButtonPRIORITY.basicSecondary}
              size={ButtonSIZE.medium}
              onClick={async () => {
                await bi.report(
                  memberWebAppButtonClickV2({
                    buttonName: ButtonNames.GotItFromCantJoinModal,
                  }),
                );

                onClose();
              }}
            >
              {t('live.modal.cant-join.cta')}
            </Button>
            {isWithOtherPaymentMethods && onSecondaryLinkClick ? (
              <TextButton
                className={classes.secondaryLink}
                onClick={async () => {
                  if (isWithOtherPaymentMethods) {
                    await bi.report(
                      memberWebAppButtonClickV2({
                        buttonName:
                          ButtonNames.OtherPaymentMethodsFromCantJoinModal,
                      }),
                    );

                    onSecondaryLinkClick();
                  }
                }}
              >
                {t('live.modal.cant-join.secondary')}
              </TextButton>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};
CantJoinModal.displayName = 'CantJoinModal';
CantJoinModal.defaultProps = {
  onSecondaryLinkClick: () => {},
  onClose: () => {},
};

import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';
import { ChallengeStep } from '@wix/ambassador-challenges-v1-challenge/types';

import { RunningIcon } from '../../../../../../../components-shared/RunningIcon';

import { classes, st } from './ChallengeAgendaStepsList.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IChallengeAgendaStepsListProps {
  className?: string;
  steps: ChallengeStep[];
}

export const ChallengeAgendaStepsList: FCWithChildren<
  IChallengeAgendaStepsListProps
> = ({ className, steps = [] }: IChallengeAgendaStepsListProps) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.contentTextAlignment);

  return (
    <ul
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment,
      })} ${className}`}
    >
      {steps.map((step) => (
        <li className={classes.stepItem}>
          <RunningIcon />
          <span className={classes.stepItemText}>
            {step?.settings?.general?.description?.title}
          </span>
        </li>
      ))}
    </ul>
  );
};

ChallengeAgendaStepsList.displayName = 'ChallengeAgendaStepsList';
ChallengeAgendaStepsList.defaultProps = {
  className: '',
};

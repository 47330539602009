import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useSettings } from '@wix/tpa-settings/react';
import format from 'date-fns/format';

import {
  Text,
  TextTypography as TYPOGRAPHY,
  TextButton,
} from 'wix-ui-tpa/cssVars';

import { classes, st } from './StepViewHidden.st.css';
import utils from '../../views/utils';
import { DateFnsLocales } from '../../../../../services/DateFnsLocales';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';

export interface IStepViewHiddenProps {
  className?: string;
  date: string;
  titleKey?: string;
  descriptionKey?: string;
  isWeekFixForCTA?: boolean;
  goToCurrentStep(e): void;
  'data-hook'?: string;
}

export function getDateExcludeTimeZone(date: string) {
  const _date = new Date(date);
  const userTimezoneOffset = _date.getTimezoneOffset() * 60000;

  return new Date(
    _date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset),
  );
}

export const StepViewHidden: React.FunctionComponent<IStepViewHiddenProps> = (
  props,
) => {
  const {
    className,
    date,
    isWeekFixForCTA,
    goToCurrentStep,
    titleKey,
    descriptionKey,
  } = props;
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();
  const { challengeData } = useChallengeData();
  const isSPC = isSelfPaced(challengeData?.challenge);
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings);

  const dateExcludeTimezone = getDateExcludeTimeZone(date);
  const dateText = (locales) =>
    dateExcludeTimezone
      ? format(new Date(dateExcludeTimezone), 'EEEE, PP', {
          locale: locales[language],
        })
      : '';
  const titleText = isSPC
    ? t(titleKey || 'live.challenges-page.hidden-step.title-spc')
    : t('live.challenges-page.hidden-step.title-scheduled');

  const descriptionText = (locales) =>
    isSPC && !descriptionKey
      ? t('live.challenges-page.hidden-step.description-spc')
      : t(
          descriptionKey ||
            'live.challenges-page.hidden-step.description-scheduled',
          {
            date: dateText(locales),
          },
        );

  return (
    <DateFnsLocales>
      {(locales) => {
        return (
          <div
            className={st(
              classes.root,
              {
                mobile: isMobile,
                alignment,
              },
              className,
            )}
            data-hook={props['data-hook']}
          >
            <Text
              tagName="h3"
              typography={TYPOGRAPHY.largeTitle}
              className={classes.title}
            >
              {titleText}
            </Text>

            <Text
              tagName="p"
              typography={TYPOGRAPHY.runningText}
              className={classes.description}
            >
              {descriptionText(locales)}
            </Text>

            <TextButton className={classes.ctaLink} onClick={goToCurrentStep}>
              {!isWeekFixForCTA
                ? t('live.challenges-page.hidden-step.cta')
                : t('live.challenges-page.hidden-step.cta-week')}
            </TextButton>
          </div>
        );
      }}
    </DateFnsLocales>
  );
};
StepViewHidden.displayName = 'StepViewHidden';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import React from 'react';

import { IFormHandleExtended } from '../../../../components/FormQuiz';

export interface ISidebarLayoutBaseContext {
  formQuizRef: React.RefObject<IFormHandleExtended>; // we need it to have a way to submit a form from btn located in a sibling component
  currentStep: ParticipantStep;
  isCurrentStepHidden: boolean;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
}

export const SidebarLayoutBaseContext =
  React.createContext<ISidebarLayoutBaseContext>({
    formQuizRef: null,
    currentStep: null,
    isCurrentStepHidden: false,
    goToCurrentStep: () => {},
    pageRef: null,
  });

export function useSidebarLayoutBase(): ISidebarLayoutBaseContext {
  return React.useContext(SidebarLayoutBaseContext);
}

import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { classes, st } from './ChallengeSummary.st.css';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { isParticipantCompletedChallenge } from '../../views/utils';
import { ChallengeDots } from '../ChallengeDots';
import { SocialGroup } from '../../../../../components-shared/SocialGroup';
import { ProgressBar } from '../../../../../components-shared/ProgressBar';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { useLeaveChallengeModal } from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import { dataHooks } from '@wix/data-hooks';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';
import { FCWithChildren } from '@wix/challenges-web-library';

export const summaryDataHooks = dataHooks<{
  main;
  title;
  progressBar;
}>('summary');

export interface ISummary {}

export const ChallengeSummary: FCWithChildren<ISummary> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const settings = useSettings();
  const { showModal } = useLeaveChallengeModal();
  const { participant } = useUser();
  const isSPC = isSelfPaced(challenge);
  const resolvedValue = participant?.stepsSummary?.completedStepsNumber
    ? (participant.stepsSummary.completedStepsNumber /
        participant.stepsSummary.stepsNumber) *
      100
    : 0;

  const isFinished = isParticipantCompletedChallenge(participant);

  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        mobile: isMobile,
      })}
      data-hook={summaryDataHooks.main()}
    >
      {isFinished ? (
        <p className={classes.completed}>{t('challenge.page.completed')}</p>
      ) : null}
      <h1 className={classes.title} data-hook={summaryDataHooks.title()}>
        {(challenge as any)?.shouldTranslateTitle
          ? t(challenge.settings.description.title)
          : challenge.settings.description.title}
      </h1>
      <div className={classes.dotsMenu}>
        <ChallengeDots
          onLeave={() => {
            showModal({
              participantId: participant.id,
              challengeId: challenge.id,
            });
          }}
        />
      </div>
      <SocialGroup className={classes.groupTitle} mode="compact" />
      {isSPC ? (
        <ProgressBar
          align={settings.get(challengeSettings.sidebarLayoutTextAlignment)}
          className={classes.progress}
          dataHook={summaryDataHooks.progressBar()}
          value={resolvedValue}
        />
      ) : null}
    </div>
  );
};

ChallengeSummary.displayName = 'ChallengeSummary';

import React from 'react';

import {
  useEnvironment,
  useTranslation,
  useBi,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';

import { convertTextAlignmentToPopoverPlacement } from '../../../../../services/settingsHelpers';
import { Dots } from '../Dots';
import { classes, st } from './ChallengeDots.st.css';
import utils from '../../views/utils';
import { dataHooks } from '@wix/data-hooks';
import { useInviteLink } from '../../../../../contexts/InviteLink/InviteLinkContext';

export interface IChallengeDotsProps {
  className?: string;
  onLeave: Function;
}

export const challengeDotsDataHooks = dataHooks<{
  main;
}>('challenge-dots');

export const ChallengeDots: React.FunctionComponent<IChallengeDotsProps> = ({
  className,
  onLeave,
}: IChallengeDotsProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getHeaderAlignByType(settings);
  const { goToInviteLink } = useInviteLink();
  const bi = useBi();

  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showOneApp =
    cssPBEnabled || settings.get(challengeSettings.displayOneApp);
  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment,
      })} ${className}`}
      data-hook={challengeDotsDataHooks.main()}
    >
      <Dots
        menuItems={[
          {
            title: t('challenge.page.dots.leave-the-channel'),
            callback: async () => {
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.LeaveTheChallenge,
                }),
              );

              onLeave();
            },
          },
          {
            title: t('challenge.page.join-via', {
              wixApp: t('challenge.wix-app'),
            }),
            isHidden: !showOneApp,
            callback: async () => {
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.GoToOneApp,
                }),
              );

              goToInviteLink();
            },
            className: classes.oneApp,
          },
        ]}
        popoverPlacement={convertTextAlignmentToPopoverPlacement(alignment)}
        onOpenMenu={async () => {
          await bi.report(
            memberWebAppButtonClickV2({
              buttonName: ButtonNames.OpenDotsMenu,
            }),
          );
        }}
      />
    </div>
  );
};
ChallengeDots.displayName = 'ChallengeDots';
ChallengeDots.defaultProps = {
  className: '',
};

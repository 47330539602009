import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';
import { ChallengeStep } from '@wix/ambassador-challenges-v1-participant/types';
import { convertTextToAccordionAlignment } from '../../../../../../../services/settingsHelpers';

import { ChallengeAgendaStepsList } from '../ChallengeAgendaStepsList';
import { Accordion } from '../../../../../../../components-shared/Accordion';

import { classes, st } from './ChallengeAgendaSection.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IChallengeAgendaSectionProps {
  className?: string;
  title: string;
  steps: ChallengeStep[];
  type: 'Accordion' | 'Calendar';
}

export const ChallengeAgendaSection: FCWithChildren<
  IChallengeAgendaSectionProps
> = ({ title, className, steps = [], type }: IChallengeAgendaSectionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.contentTextAlignment);
  const isRegularAccordion = type === 'Accordion';

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        alignment,
      })} ${className}`}
    >
      <Accordion
        title={title}
        disabled={steps.length === 0}
        subtitle={
          isRegularAccordion && steps.length > 0
            ? t('live.challenges-page.agenda.steps-count', {
                count: steps.length,
              })
            : null
        }
        opened={!isRegularAccordion}
        className={classes.accordion}
        align={convertTextToAccordionAlignment(alignment)}
        buttonRole="button"
        data-hook={`challenge-agenda-${title}`}
      >
        <ChallengeAgendaStepsList steps={steps} />
      </Accordion>
    </div>
  );
};

ChallengeAgendaSection.displayName = 'ChallengeAgendaSection';
ChallengeAgendaSection.defaultProps = {
  className: '',
};

import React from 'react';

import { useMemberPaidPlans } from '../../../../../contexts/PaidPlans/paidPlansContext';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { getPricingOptions } from './helpers/getPricingOptions';
import { getChallengeEligiblePaidPlans } from './helpers/getChallengeEligiblePaidPlans';
import { getPaidPlanStartPrice } from './helpers/getPaidPlanPrice';
import {
  FCWithChildren,
  getPaidPlansJoinedName,
  isHiddenPlansOnly,
  serverPricingToClientPriceAndCurrencyString,
} from '@wix/challenges-web-library';

import { SinglePricingOption } from '../SinglePricingOption/SinglePricingOption';

import { IPricingProps, SelectedPaymentOption } from './interfaces';
import {
  FREE_CHALLENGE_PRICING,
  INCLUDED_IN_YOUR_PLAN_DATA_HOOK,
  ONLY_HIDDEN_PAID_PLANS_DATA_HOOK,
  ONLY_ONE_TIME_PAYMENT_PRICING,
  ONLY_PAID_PLANS_DATA_HOOK,
} from './constants';
import { getPricingViewOptions } from '../../../../../services/Pricing/getPricingViewOptions';
import { TextAlignment } from '../../../Settings/challengeSettings/challengeSettings.types';

import { classes, st } from './Pricing.st.css';
import {
  RadioButton,
  RadioButtonGroup,
  RadioButtonTheme,
} from 'wix-ui-tpa/cssVars';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';

export const SinglePricingSection: FCWithChildren<{
  className?: string;
  alignment: TextAlignment;
  dataHook?: string;
  content: string;
}> = ({ className, alignment, dataHook, content }) => {
  return (
    <div
      className={st(
        classes.root,
        {
          alignment,
          type: 'single',
        },
        className,
      )}
      data-hook={dataHook || ''}
    >
      <SinglePricingOption>{content}</SinglePricingOption>
    </div>
  );
};

export const ChoicePricingSection: FCWithChildren<{
  className?: string;
  alignment: TextAlignment;
  dataHook?: string;
  isDisabled: boolean;
  selectedValue: any;
  onSelect(value: any): void;
  options: any[];
}> = ({
  className,
  alignment,
  dataHook,
  isDisabled,
  selectedValue,
  onSelect,
  options,
}) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      className={st(
        classes.root,
        {
          alignment,
          type: 'choices',
        },
        className,
      )}
      dir={alignment === TextAlignment.Right ? 'rtl' : 'ltr'}
      data-hook={dataHook || ''}
    >
      <RadioButtonGroup
        name="price-selection"
        withSpacing
        className={classes.radioButtonGroup}
        disabled={isDisabled}
        theme={RadioButtonTheme.Box}
        value={selectedValue}
        fluid={true}
        onChange={onSelect}
        newErrorMessage={true}
      >
        {options.map((option, key) => {
          return (
            <RadioButton
              className={classes.radioOption}
              key={key}
              value={option.value}
              label={option.title}
              suffix={
                isMobile ? null : (
                  <span className={classes.noWrap}>{option.valueTitle}</span>
                )
              }
              subtext={isMobile ? option.valueTitle : null}
            />
          );
        })}
      </RadioButtonGroup>
    </div>
  );
};

ChoicePricingSection.displayName = 'ChoicePricingSection';

export const Pricing: FCWithChildren<IPricingProps> = ({
  className,
  disabled,
  disabledEligible,
  selectedPaymentOption,
  onPaymentSelected,
  alignment,
}: IPricingProps) => {
  const { t } = useTranslation();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { userPaidPlans, eligiblePlans } = useMemberPaidPlans();

  const { isHasSinglePayment, challengePaidPlans } = getPricingViewOptions(
    challenge,
    userPaidPlans,
    eligiblePlans,
    false,
    false,
  );

  const { challengePaidPlans: challengeVisiblePaidPlans } =
    getPricingViewOptions(challenge, userPaidPlans, eligiblePlans, true, false);

  /*
    The order below is important.
    Check the already purchased pps first.
    Then, do a check for hidden only pps and process it in the correct way.
    Then we can remove hidden / archived pps from the list and work only with active ones.
   */

  /* User already purchased one of pricing plans (eligible means paid) */

  const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
    eligiblePlans,
    userPaidPlans,
    challenge.id,
  );

  if (challengeEligiblePaidPlans.length && !disabledEligible) {
    if (selectedPaymentOption !== SelectedPaymentOption.PaidPlans) {
      onPaymentSelected(SelectedPaymentOption.PaidPlans);
    }

    return (
      <SinglePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        dataHook={INCLUDED_IN_YOUR_PLAN_DATA_HOOK}
        content={t('pricing.payment-option.subscription.already-included')}
      />
    );
  }

  /* There are only hidden pricing plans */

  const isHiddenOnly = isHiddenPlansOnly(
    isHasSinglePayment,
    challengePaidPlans,
  );

  if (isHiddenOnly) {
    if (selectedPaymentOption !== SelectedPaymentOption.PaidPlans) {
      onPaymentSelected(SelectedPaymentOption.PaidPlans);
    }

    return (
      <SinglePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        dataHook={ONLY_HIDDEN_PAID_PLANS_DATA_HOOK}
        content={t('challenge.page.pricing-options.paid-general')}
      />
    );
  }

  /* Here we can get the pricing options for visible plans only */

  const pricingOptions = getPricingOptions(
    t,
    challenge,
    userPaidPlans,
    eligiblePlans,
    true,
    false,
  );

  if (!selectedPaymentOption && pricingOptions?.[0]) {
    onPaymentSelected(pricingOptions[0].value as any);
  }

  /* Challenge is free */

  if (!pricingOptions.length) {
    return (
      <SinglePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        dataHook={FREE_CHALLENGE_PRICING}
        content={t('challenge.page.pricing-options.free')}
      />
    );
  }

  /* Challenges has different pricing options (one time payment + active paid plans)  */

  if (pricingOptions.length > 1) {
    return (
      <ChoicePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        isDisabled={disabled || pricingOptions.length === 1}
        selectedValue={selectedPaymentOption}
        onSelect={onPaymentSelected}
        options={pricingOptions}
      />
    );
  }

  /* Pricing plans only */

  if (pricingOptions[0].value === SelectedPaymentOption.PaidPlans) {
    const paidPlanName = getPaidPlansJoinedName(
      t,
      challengeVisiblePaidPlans,
      'pricing.payment-option.subscription_icu',
      true,
    );
    const paidPlanStartPrice = getPaidPlanStartPrice(
      challengeVisiblePaidPlans,
      t,
    );

    return (
      <SinglePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        dataHook={ONLY_PAID_PLANS_DATA_HOOK}
        content={`${paidPlanName}, ${paidPlanStartPrice}`}
      />
    );
  }

  /* One time payment only */

  if (pricingOptions[0].value === SelectedPaymentOption.SinglePayment) {
    if (selectedPaymentOption !== SelectedPaymentOption.SinglePayment) {
      onPaymentSelected(SelectedPaymentOption.SinglePayment);
    }

    return (
      <SinglePricingSection
        className={className}
        alignment={alignment || TextAlignment.Left}
        dataHook={ONLY_ONE_TIME_PAYMENT_PRICING}
        content={serverPricingToClientPriceAndCurrencyString(
          challenge.settings.pricing as any,
        )}
      />
    );
  }
};

import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { IChallengeJoinRestriction } from '../../interfaces';
import { Notification } from '../../../../../components-shared/Notification';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { useUser } from '../../../../../contexts/User/UserContext';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';

import { classes, st } from './ChallengeNotification.st.css';

export enum IChallengeNotificationType {
  Visitor = 'Visitor',
  Sidebar = 'Sidebar',
}

export interface IChallengeNotificationProps {
  className?: string;
  challengeJoinRestrictions: IChallengeJoinRestriction[];
  type: IChallengeNotificationType;
}

export const ChallengeNotification: React.FunctionComponent<
  IChallengeNotificationProps
> = ({
  className,
  challengeJoinRestrictions,
  type,
}: IChallengeNotificationProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const firstRestriction = challengeJoinRestrictions?.[0];
  const { userType } = useUser();
  const alignmentKey =
    type === IChallengeNotificationType.Visitor
      ? challengeSettings.headerTextAlignment
      : challengeSettings.sidebarLayoutTextAlignment;

  let restrictionContent = null;

  switch (firstRestriction) {
    case IChallengeJoinRestriction.FINISHED:
      if (type === IChallengeNotificationType.Visitor) {
        restrictionContent = t('challenge.finished');
      } else {
        restrictionContent = t('challenge.page.restrictions.finished-already');
      }
      break;
    case IChallengeJoinRestriction.MAX_PARTICIPANTS:
      restrictionContent = t('challenge.page.restrictions.max-participants');
      break;
    case IChallengeJoinRestriction.OFFLINE_PAYMENT_PENDING:
      restrictionContent = t('challenge.payment-request.notification');
      break;
    case IChallengeJoinRestriction.SPECIFIC_STARTED:
      restrictionContent = t('challenge.page.restrictions.specific-started');
      break;
    default:
  }

  if (
    type === IChallengeNotificationType.Visitor &&
    userType === ParticipantState.JOIN_REQUESTED &&
    !restrictionContent
  ) {
    restrictionContent = t('challenge.join-request.notification');
  }

  return restrictionContent ? (
    <Notification
      className={`${st(classes.root, {
        type: type.toLowerCase(),
      })} ${className}`}
      alignment={settings.get(alignmentKey)}
      withErrorIcon={true}
      content={restrictionContent}
    />
  ) : null;
};
ChallengeNotification.displayName = 'ChallengeNotification';
ChallengeNotification.defaultProps = {
  className: '',
};

import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import React from 'react';
import { ResolutionStatus } from '@wix/ambassador-challenges-v1-participant/types';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';
import {
  isParticipantCompletedChallenge,
  isStepResolved,
} from '../../views/utils';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../../../../editor/types/Experiments';
import { FCWithChildren } from '@wix/challenges-web-library';

export const UndoStepButton: FCWithChildren<{
  children: React.ReactNode;
}> = (props) => {
  const { resolveStep, isResolveStepRequestInProgress } = useResolveStep();
  const { currentStep } = useSidebarLayoutBase();
  const { participant } = useUser();
  const { experiments } = useExperiments();

  if (
    !isStepResolved(currentStep) ||
    isParticipantCompletedChallenge(participant) ||
    !experiments.enabled(Challenges.enableUndoButton)
  ) {
    return null;
  }

  return (
    <TextButton
      disabled={isResolveStepRequestInProgress}
      priority={TextButtonPriority.primary}
      onClick={() => {
        void resolveStep({
          stepId: currentStep.id,
          status: ResolutionStatus.UNDO,
          successMessage: {
            key: currentStep.quizSubmission
              ? 'toast.quiz-undo'
              : 'toast.step-undo',
            type: ToastType.success,
          },
        });
      }}
    >
      {props.children}
    </TextButton>
  );
};

import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { TranslateFunction } from '@wix/challenges-web-library';
import {
  IUserType,
  UserState,
} from '../../../../../../../../../contexts/User/UserContext';

interface IGetJoinButtonTextProps {
  t: TranslateFunction;
  userType: IUserType;
  isAvailableForJoinImmediately: boolean;
  joinButtonTextFromSettings: string;
  requestToJoinButtonTextSettings: string;
}

export function getJoinButtonText(args: IGetJoinButtonTextProps): string {
  const {
    t,
    userType,
    isAvailableForJoinImmediately,
    joinButtonTextFromSettings,
    requestToJoinButtonTextSettings,
  } = args;

  switch (userType) {
    case UserState.VISITOR:
    case UserState.MEMBER:
    case ParticipantState.JOIN_REJECTED:
    case ParticipantState.LEFT:
    case ParticipantState.REMOVED:
    case ParticipantState.COMPLETED:
    case ParticipantState.FAILED:
      return isAvailableForJoinImmediately
        ? joinButtonTextFromSettings
        : requestToJoinButtonTextSettings;
    case ParticipantState.JOINED:
    case ParticipantState.RUNNING:
      return t('challenge.page.join-via', {
        wixApp: t('challenge.wix-app'),
      });
    case ParticipantState.JOIN_REQUESTED:
      return t('challenge.join-request.cancel');
    case ParticipantState.INVITED:
    case ParticipantState.PAYMENT_REQUESTED:
    case ParticipantState.PAYMENT_STARTED:
    default:
      return joinButtonTextFromSettings;
  }
}

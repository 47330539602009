export enum SectionAbbreviation {
  about = '1',
  overview = '2',
  instructor = '3',
  start_date = '4',
  price = '5',
  group_discussion = '6',
  share = '7',
}

export const sectionIds = [
  'about',
  'overview',
  'instructor',
  'start_date',
  'price',
  'group_discussion',
  'share',
] as const;

export type VisitorSections = typeof sectionIds[number];

import React from 'react';

import {
  IChallengeJoinRestriction,
  IChallengePageProps,
  IChallengePageState,
} from '../../interfaces';

import {
  InjectedBiLoggerProps,
  IWixSDKContext,
  withBi,
  withExperiments,
} from '@wix/yoshi-flow-editor';

import { memberWebOnAfterAction } from '@wix/bi-logger-challenges-member-web/v2';
import { Spinner } from '../../../../../components-shared/Spinner';

import { classes, st } from './VisitorPage.st.css';
import { ActionTypes } from '../../../../../contexts/main/biInterfaces';
import { format } from 'date-fns';
import {
  getNearestStartDateFromFutureEligiblePPs,
  isChallengeHasEligiblePPsInFutureOnly,
} from '../../components/Pricing/helpers/getChallengeEligiblePaidPlans';
import uuid from 'uuid';
import { isOfflinePaymentStarted } from '../../../../../contexts/User/helpers/isOfflinePaymentStarted';

import { dataHooks } from '@wix/data-hooks';
import {
  ChallengeNotification,
  IChallengeNotificationType,
} from '../../components/ChallengeNotification';
import { VisitorPageHeaderBox } from './components/VisitorPageHeaderBox';
import { IJoinValidationAction } from './components/sections/VisitorPageJoinButton/joinValidation';

import { VisitorPageBaseContext } from './contexts/VisitorPageBase/VisitorPageBaseContext';
import { VisitorPageCantJoinModal } from './components/body/VisitorPageCantJoinModal';
import { VisitorPageMain } from './components/VisitorPageMain';
import { ProgramUnavailableModal } from '../../components/ProgramUnavailableModal/ProgramUnavailableModal';

export interface IExtendVisitorPage {
  isMobile: boolean;
  isStretched: boolean;
  lng: string;
  experiments: any;
}

export const visitorPageDataHooks = dataHooks<{
  main;
  spinner;
  desktopMedia;
  mobileMedia;
  content;
  author;
  startDate;
}>('visitor-page');

class VisitorPageComponent extends React.PureComponent<
  IChallengePageProps &
    IWixSDKContext &
    IExtendVisitorPage &
    InjectedBiLoggerProps,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';

  constructor(
    props: IChallengePageProps &
      IWixSDKContext &
      IExtendVisitorPage &
      InjectedBiLoggerProps,
  ) {
    super(props);

    this.state = {
      selectedPaymentOption: null,
      isEmptyStartDateError: false,
      startDate: format(this.getStartMinDate(), 'dd/MM/yyyy'),
      challengeJoinRestrictions: [],
      isCantJoinModalOpened: false,
      isDisableEligiblePlans: false,
      isPremiumOutdatedModalOpened: false,
    };

    this.checkRestrictions();
  }

  getStartMinDate() {
    const { challengeData, eligiblePlans, userPaidPlans } = this.props;
    const challengeId = challengeData?.challenge?.id;

    return isChallengeHasEligiblePPsInFutureOnly(
      eligiblePlans,
      userPaidPlans,
      challengeId,
    )
      ? getNearestStartDateFromFutureEligiblePPs(
          eligiblePlans,
          userPaidPlans,
          challengeId,
        )
      : new Date();
  }

  checkRestrictions() {
    const { participant } = this.props;
    const participantsNumber =
      this.props.challengeData?.challenge?.participantsSummary
        ?.participantsNumber;
    const maxParticipants =
      this.props.challengeData?.challenge?.settings?.accessRestrictions
        ?.maxParticipants;

    if (
      this.props?.challengeData?.challenge?.transitions?.[0]?.state ===
      'FINISHED'
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.FINISHED,
      ); // ! Called from constructor
    }

    if (isOfflinePaymentStarted(participant)) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.OFFLINE_PAYMENT_PENDING,
      ); // ! Called from constructor
    }

    if (
      participantsNumber &&
      maxParticipants &&
      !isNaN(participantsNumber) &&
      !isNaN(maxParticipants) &&
      participantsNumber >= maxParticipants
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.MAX_PARTICIPANTS,
      ); // ! Called from constructor
    }
  }

  onStartDateChange = (value: any) => {
    this.setState(
      {
        isEmptyStartDateError: false,
        startDate: value ? format(new Date(value), 'dd/MM/yyyy') : null,
      },
      async () => {
        await this.props.bi.report(
          memberWebOnAfterAction({
            actionId: uuid(),
            actionType: ActionTypes.START_DATE_SELECTED,
            startDate_: (value
              ? new Date(value).toISOString()
              : undefined) as any,
          }),
        );
      },
    );
  };

  render() {
    const { challengeData, isLoading, isMobile } = this.props;

    return challengeData?.challenge ? (
      <main
        data-hook={visitorPageDataHooks.main()}
        className={st(classes.root, {
          mobile: isMobile,
        })}
      >
        {isLoading && (
          <div
            className={classes.loader}
            data-hook={visitorPageDataHooks.spinner()}
          >
            <Spinner />
          </div>
        )}
        <VisitorPageBaseContext.Provider
          value={
            {
              ...this.state,
              onPaymentSelected: (selectedOption) => {
                this.setState({ selectedPaymentOption: selectedOption });
              },
              getStartMinDate: this.getStartMinDate.bind(this),
              onStartDateChange: this.onStartDateChange.bind(this),
              onJoinValidationError: (error: IJoinValidationAction) => {
                switch (error) {
                  case IJoinValidationAction.START_DATE_ERROR:
                    this.setState({ isEmptyStartDateError: true });
                    break;
                  case IJoinValidationAction.FUTURE_PLANS_ONLY_ERROR:
                    this.setState({ isCantJoinModalOpened: true });
                    break;
                }
              },
              onCantJoinSecondaryClick: () => {
                this.setState({
                  isDisableEligiblePlans: true,
                  isCantJoinModalOpened: false,
                });
              },
              onCantJoinClose: () => {
                this.setState({
                  isCantJoinModalOpened: false,
                });
              },
              setIsPremiumOutdatedModalOpened: (value: boolean) => {
                this.setState({
                  isPremiumOutdatedModalOpened: value,
                });
              },
            } as any
          }
        >
          {this.renderWithChallenge()}
        </VisitorPageBaseContext.Provider>
      </main>
    ) : null;
  }

  renderWithChallenge() {
    return (
      <>
        <ChallengeNotification
          type={IChallengeNotificationType.Visitor}
          challengeJoinRestrictions={this.state.challengeJoinRestrictions}
        />
        <VisitorPageHeaderBox className={classes.headerBox} />
        <VisitorPageMain />
        <VisitorPageCantJoinModal />
        <ProgramUnavailableModal />
      </>
    );
  }
}

export default withExperiments(withBi(VisitorPageComponent));

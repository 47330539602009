import React from 'react';

export const GroupIcon = (props: { className?: string }) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          transform="translate(-264 -351)"
        >
          <g transform="translate(261 347)">
            <g transform="translate(3 4)">
              <path d="M7 9a3 3 0 013 3v4H0v-4a3 3 0 013-3h4zm0 1H3a2 2 0 00-1.995 1.85L1 12v3h8v-3a2 2 0 00-1.85-1.995L7 10zm8-3a3 3 0 013 3v4h-7v-1h6v-3a2 2 0 00-1.85-1.995L11 8c-.694 0-1.306.354-1.665.89l-.832-.554A2.997 2.997 0 0111 7h4zM5 2a3 3 0 110 6 3 3 0 010-6zm0 1a2 2 0 100 4 2 2 0 000-4zm8-3a3 3 0 110 6 3 3 0 010-6zm0 1a2 2 0 100 4 2 2 0 000-4z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import React from 'react';

import {
  IPageSectionProps,
  PageSection,
} from '../../../../../components-shared/PageSection';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { st, classes } from './ChallengeOverviewSection.st.css';
import utils from '../../views/utils';
import { FCWithChildren } from '@wix/challenges-web-library';

interface IChallengeOverviewSectionProps extends IPageSectionProps {
  title?: string;
  dataHook?: string;
  className?: string;
}

// todo: styles: common => title => content
// todo: mobile => alignment

const CHALLENGE_OVERVIEW_TITLE = 'challenge-overview-title';
export const ChallengeOverviewSection: FCWithChildren<
  IChallengeOverviewSectionProps
> = ({ className, children, ...rest }) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <PageSection
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment: utils.getContentAlignByType(settings),
      })} ${className}`}
      {...rest}
      titleDataHook={CHALLENGE_OVERVIEW_TITLE}
    >
      {children}
    </PageSection>
  );
};

import React from 'react';

import { IAccordionProps, Accordion } from '../Accordion';
import { FCWithChildren } from '@wix/challenges-web-library';

import { st, classes } from './SectionsAsTilesAccordion.st.css';

export interface ISectionsAccordionProps extends IAccordionProps {
  id?: string;
  emptyChildren?: boolean;
}

export const SectionsAsTilesAccordion: FCWithChildren<
  ISectionsAccordionProps
> = (props) => {
  const { title, id, className, opened, emptyChildren, ...rest } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <Accordion
      id={id}
      title={title}
      className={st(
        classes.root,
        {
          emptyChildren: props.emptyChildren,
        },
        className,
      )}
      onIconClick={() => {
        setIsOpen(!isOpen);
      }}
      opened={isOpen || props.opened}
      {...rest}
    >
      {props.children}
    </Accordion>
  );
};

import React from 'react';

import { MediaItem } from '@wix/ambassador-challenges-v1-participant/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Video } from '../Video';
import { getMediaImagePreview } from '../../selectors/media';
import { st, classes } from './MediaCover.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useGeneralData } from '../../contexts/GeneralDataProvider/GeneralDataContext';

export interface IMediaCover {
  media: MediaItem;
  className?: string;
  maxHeight?: number;
  fit?: 'contain' | 'cover'; // by default cover
  dataHook?: string;
  altText?: string;
}

export const MediaCover: FCWithChildren<IMediaCover> = (props) => {
  const { isMobile } = useEnvironment();
  const { fit = 'cover', dataHook = '' } = props;
  const { isFullWidthLayout } = useGeneralData();

  if (!props.media) {
    return null;
  }

  return (
    <figure
      data-hook={dataHook}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          fit,
          fullWidth: isFullWidthLayout,
        },
        props.className,
      )}
    >
      {props.media?.image ? (
        <img
          style={{
            maxHeight: props.maxHeight,
          }}
          alt={props.altText}
          className={classes.mediaImage}
          src={getMediaImagePreview(props.media.image, 1000, 800)}
        />
      ) : (
        <Video
          altText={props.altText}
          className={classes.mediaImage}
          thumbnail={props.media?.video?.thumbnail}
          video={props.media?.video}
        />
      )}
    </figure>
  );
};

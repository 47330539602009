import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { format } from 'date-fns';
import utils from '../../views/utils';
import { classes } from './StepInTheFutureGuard.st.css';
import { DateFnsLocales } from '../../../../../services/DateFnsLocales';
import { FCWithChildren } from '@wix/challenges-web-library';

export const StepInTheFutureGuard: FCWithChildren<{
  children: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation();
  const { currentStep } = useSidebarLayoutBase();
  const { language } = useEnvironment();
  const isStepLocked = utils.isStepLocked(currentStep);

  return isStepLocked ? (
    <DateFnsLocales>
      {(locales) => (
        <p className={classes.stepUnavailable}>
          {t('challenge.page.step-unavailable.warning', {
            date: format(new Date(currentStep?.dateFrame?.start), 'PP', {
              locale: locales[language],
            }),
          })}
        </p>
      )}
    </DateFnsLocales>
  ) : (
    <>{props.children}</>
  );
};

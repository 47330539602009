export const getSectionTitle = (
  sectionId,
  t: (key: string) => string,
): string => {
  switch (sectionId) {
    case 'about':
      return t('settings.visitor-page.sections.about.title');
    case 'overview':
      return t('settings.visitor-page.sections.overview.title');
    case 'instructor':
      return t('settings.visitor-page.sections.instructor.title');
    case 'start_date':
      return t('settings.visitor-page.sections.start_date.title');
    case 'price':
      return t('settings.visitor-page.sections.price.title');
    case 'group_discussion':
      return t('settings.visitor-page.sections.group_discussion.title');
    case 'share':
      return t('settings.visitor-page.sections.share.title');
  }
};

import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { dataHooks } from '@wix/data-hooks';
import {
  Video as VideoType,
  Image as ImageType,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { getMediaImagePreview } from '../../selectors/media';
import {
  getVideoUrl,
  getVideoTypeFromUrl,
  FCWithChildren,
} from '@wix/challenges-web-library';
import PlayIcon from '../../assets/icons/play.svg';

import { st, classes } from './Video.st.css';

interface IVideoProps {
  className?: string;
  thumbnail?: ImageType;
  altText?: string;
  video?: VideoType;
}

export const videoDataHooks = dataHooks<{
  main;
  preview;
  player;
}>('visitor-page');

export const Video: FCWithChildren<IVideoProps> = (props) => {
  const { isMobile } = useEnvironment();
  const [isPreview, setIsPreview] = React.useState<boolean>(true);
  const videoElement = React.useRef<HTMLVideoElement>();

  const thumbnailUrl = getMediaImagePreview(props.thumbnail, 1000, 800);
  const videoUrl = getVideoUrl(props.video.url);
  const videoType = getVideoTypeFromUrl(videoUrl);

  const onPreviewClick = React.useCallback(() => {
    setIsPreview(false);
    videoElement.current.play();
  }, []);

  return (
    <div
      className={st(
        classes.root,
        { mobile: isMobile, isVideoVisible: !isPreview },
        props.className,
      )}
      data-hook={videoDataHooks.main()}
    >
      {isPreview && thumbnailUrl && (
        <div className={classes.preview} data-hook={videoDataHooks.preview()}>
          <img
            alt={props.altText}
            src={thumbnailUrl}
            className={classes.previewImage}
          />
          <button
            role="button"
            className={classes.playIcon}
            onClick={onPreviewClick}
          >
            <PlayIcon />
          </button>
        </div>
      )}

      <video
        ref={videoElement}
        className={classes.videoPlayer}
        data-hook={videoDataHooks.player()}
        controls
        width={1000}
        height={800}
      >
        <source src={videoUrl} type={videoType} />
      </video>
    </div>
  );
};

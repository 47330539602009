import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './StepControls.st.css';
import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';
import { FCWithChildren } from '@wix/challenges-web-library';

export const FinishedNotification: FCWithChildren = () => {
  const { t } = useTranslation();

  return (
    <SidebarControlsContainer>
      <p
        className={classes.finishedNotification}
        data-hook="finished-notification"
      >
        {t('challenge.page.restrictions.finished-already')}
      </p>
    </SidebarControlsContainer>
  );
};

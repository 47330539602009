import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import { OverviewIcon } from '../../../../../components-shared/OverviewIcon';

import { st, classes } from '../StepsListAsTiles/StepsListAsTiles.st.css';

import challengeSettings from '../../../settingsParams';
import { useCSSPBStyle } from '../../../../../hooks/useCSSPBStyle';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IOverviewSidebar {
  isActive: boolean;
  onStepChosen: Function;
}

export const OverviewSidebar: FCWithChildren<IOverviewSidebar> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const getCSSPBStyle = useCSSPBStyle();
  if (!cssPBEnabled && !settings.get(challengeSettings.overviewTabDisplay)) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
      })}
      style={getCSSPBStyle({ displayVar: '--opgOverviewTabDisplay' })}
    >
      <ul className={classes.stepsList}>
        <li
          className={`${classes.stepItem} ${classes.overviewItem} ${
            props.isActive ? classes.stepItemActive : ''
          }`}
          onClick={() => {
            props.onStepChosen(null);
          }}
        >
          <button className={classes.stepLink} onClick={() => {}}>
            <OverviewIcon />
            <div className={classes.gapBetweenTitleAndIcon} />
            <p className={`${classes.stepItemTitle}`}>
              {t('live.challenges-page-sidebar.overview-title')}
            </p>
          </button>
        </li>
      </ul>
    </div>
  );
};

OverviewSidebar.displayName = 'OverviewSidebarItem';

import React from 'react';

import { Text, Button } from 'wix-ui-tpa/cssVars';
import { Pricing } from '../../../../ChallengesPage/Widget/components/Pricing/Pricing';
import { SelectedPaymentOption } from '../../../../ChallengesPage/Widget/components/Pricing/interfaces';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { useUser } from '../../../../../contexts/User/UserContext';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';
import { dataHooks } from '@wix/data-hooks';
import { FCWithChildren } from '@wix/challenges-web-library';

import { classes, st } from './ProgramRejoin.st.css';
import { TextAlignment } from '../../../../ChallengesPage/Settings/challengeSettings/challengeSettings.types';

export interface IProgramRejoinProps {
  className?: string;
  type: 'SidebarGeneral' | 'SidebarOverview';
  prefix?: React.ReactNode;
}

export const programRejoinDataHooks = dataHooks<{
  main;
}>('program-rejoin');

export const ProgramRejoin: FCWithChildren<IProgramRejoinProps> = ({
  className,
  type,
  prefix,
}: IProgramRejoinProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const { userType, join } = useUser();

  const [selectedPaymentOption, setSelectedPaymentOption] =
    React.useState<SelectedPaymentOption>(null);

  if (userType !== ParticipantState.SUSPENDED) {
    return null;
  }

  return (
    <section
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
      })} ${className}`}
      data-hook={programRejoinDataHooks.main()}
    >
      {prefix || null}
      <div className={classes.content}>
        <h4 className={classes.title}>
          {t('live.challenges-page.renew-section.title')}
        </h4>
        <Text tagName="p" className={classes.description}>
          {t('live.challenges-page.renew-section.description')}
        </Text>

        <Pricing
          className={classes.pricing}
          alignment={TextAlignment.Left}
          selectedPaymentOption={selectedPaymentOption}
          onPaymentSelected={setSelectedPaymentOption}
        />

        <Button
          className={classes.button}
          fullWidth={isMobile}
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.RenewParticipation,
              }),
            );
            join(selectedPaymentOption, null);
          }}
        >
          {t('live.challenges-page.renew-section.cta')}
        </Button>
      </div>
    </section>
  );
};
ProgramRejoin.displayName = 'ProgramRejoin';
ProgramRejoin.defaultProps = {
  className: '',
};

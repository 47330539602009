import { getPricingViewOptions } from '../../../../../../services/Pricing/getPricingViewOptions';
import {
  getPaidPlansJoinedName,
  IUserPaidPlan,
  serverPricingToClientPriceAndCurrencyString,
} from '@wix/challenges-web-library';
import { PAID_PLAN_DATA_HOOK, SINGLE_PAYMENT_DATA_HOOK } from '../constants';
import { SelectedPaymentOption } from '../interfaces';
import { getPaidPlanStartPrice } from './getPaidPlanPrice';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { MemberOrdersByPlan } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { RadioOption } from '../../../../../../components-shared/RadioGroup/RadioGroup';

export interface IPricingOption extends RadioOption {
  value: SelectedPaymentOption;
}

export function getPricingOptions(
  t: (key: string) => string,
  challenge: Challenge,
  userPaidPlans: IUserPaidPlan[],
  eligiblePlans: MemberOrdersByPlan[] = [],
  visiblePlansOnly: boolean = false,
  includeEligiblePlans: boolean = true,
): IPricingOption[] {
  const { isHasPaidPlans, isHasSinglePayment, challengePaidPlans } =
    getPricingViewOptions(
      challenge,
      userPaidPlans,
      eligiblePlans,
      visiblePlansOnly,
      includeEligiblePlans,
    );

  const pricingOptions = [];

  if (isHasSinglePayment) {
    const SINGLE_PAYMENT = {
      dataHook: SINGLE_PAYMENT_DATA_HOOK,
      title: t(`challenge.page.pricing-options.single-payment`),
      valueTitle: serverPricingToClientPriceAndCurrencyString(
        challenge.settings.pricing as any,
      ),
      value: SelectedPaymentOption.SinglePayment,
    };

    pricingOptions.push(SINGLE_PAYMENT);
  }

  if (isHasPaidPlans) {
    const PAID_PLANS = {
      dataHook: PAID_PLAN_DATA_HOOK,
      title: getPaidPlansJoinedName(
        t,
        challengePaidPlans,
        'pricing.payment-option.subscription_icu',
        visiblePlansOnly,
      ),
      valueTitle: getPaidPlanStartPrice(challengePaidPlans, t),
      value: SelectedPaymentOption.PaidPlans,
    };

    pricingOptions.push(PAID_PLANS);
  }

  return pricingOptions;
}

import React from 'react';

import { ProgressBar as ProgressBarTPA } from 'wix-ui-tpa/cssVars';
import { TextAlignment } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import { useUser } from '../../contexts/User/UserContext';
import { FCWithChildren } from '@wix/challenges-web-library';

import { st, classes } from './ProgressBar.st.css';

export interface IProgressBarProps {
  className?: string;
  dataHook?: string;
  align: TextAlignment;
  value: number;
}

export const ProgressBar: FCWithChildren<IProgressBarProps> = (props) => {
  const progressValue = isNaN(props.value) ? 0 : props.value;
  const { isParticipantInLockedState } = useUser();

  if (isParticipantInLockedState) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {}, props.className)}
      data-hook={props.dataHook}
      dir={props.align === TextAlignment.Right ? 'rtl' : 'auto'}
    >
      <ProgressBarTPA value={progressValue} showProgressIndication={true} />
    </div>
  );
};

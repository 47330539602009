import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Video } from '../../../../../../../components-shared/Video';
import { useChallengeData } from '../../../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import challengeSettings from '../../../../../settingsParams';
import {
  imageDataToMediaUrl,
  IImageData,
  FCWithChildren,
} from '@wix/challenges-web-library';
import { dataHooks } from '@wix/data-hooks';

import { st, classes } from './VisitorPageMedia.st.css';
import { useCSSPBStyle } from '../../../../../../../hooks/useCSSPBStyle';
import { getProgramAltImageText } from '../../../../../../../selectors/getProgramImageAltText';

export const visitorPageMediaDataHooks = dataHooks<{
  desktopMedia;
  mobileMedia;
}>('visitor-page-media');

interface IVisitorPageMediaProps {
  className?: string;
  isForMobile: boolean;
  isInline: boolean;
}

export const VisitorPageMedia: FCWithChildren<IVisitorPageMediaProps> = ({
  isForMobile,
  isInline,
  className,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const challengeDescriptionMedia = challenge?.settings?.description?.media;
  const isMockImage = !challengeDescriptionMedia && !isMobile && isInline;
  const getStyle = useCSSPBStyle();
  const cssPBStyle = getStyle({ displayVar: '--opgDisplayHeaderImage' });
  const displayHeader = cssPBStyle
    ? true
    : settings.get(challengeSettings.displayHeaderImage);
  const isShouldBeRendered =
    displayHeader &&
    (challengeDescriptionMedia || isMockImage) &&
    ((isMobile && isForMobile) || (!isMobile && !isForMobile));

  const isVideo = challengeDescriptionMedia?.video;

  return isShouldBeRendered ? (
    <figure
      className={`${st(classes.root, {
        mobile: isMobile,
        inline: isInline,
        imageRatio: settings.get(challengeSettings.imageRatio),
        cropSelection: settings.get(challengeSettings.cropSelection),
      })} ${className}`}
      data-hook={
        isForMobile
          ? visitorPageMediaDataHooks.mobileMedia()
          : visitorPageMediaDataHooks.desktopMedia()
      }
      style={cssPBStyle}
    >
      <div className={classes.imageRatioBox}>
        <div className={classes.imageRatioBoxInner}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isVideo ? (
            <Video
              altText={getProgramAltImageText(challenge)}
              className={classes.mediaItem}
              thumbnail={challengeDescriptionMedia?.video?.thumbnail}
              video={challengeDescriptionMedia?.video}
            />
          ) : isMockImage ? (
            <div className={classes.mediaMockImage} />
          ) : (
            <img
              alt={getProgramAltImageText(challenge)}
              className={classes.mediaItem}
              width={isForMobile ? undefined : 1000}
              height={isForMobile ? undefined : 800}
              src={imageDataToMediaUrl({
                ...(challengeDescriptionMedia?.image as IImageData),
                width: 1000,
                height: 800,
              })}
            />
          )}
        </div>
      </div>
    </figure>
  ) : null;
};
